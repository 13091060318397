import Alert from '@attendance-web-app/components/Common/Alert';
import CustomDropDownSelect from '@attendance-web-app/components/Common/CustomDropDownSelect';
import CustomFormCalendar from '@attendance-web-app/components/Common/CustomFormCalendar';
import CustomModal from '@attendance-web-app/components/Common/CustomModal';
import ErrorLabel from '@attendance-web-app/components/Common/ErrorLabel';
import { getBanksList } from '@attendance-web-app/services/bank.services';
import paymentServices from '@attendance-web-app/services/payment.services';
import {
  IPaymentVendor,
  IcreatePaymentVendorBody,
  VendorPaymentModalType
} from '@attendance-web-app/types/vendor/type';
import { formatAmountToStandardFormat, returnDropdownOption } from '@attendance-web-app/utils';
import useCustomListApi from '@attendance-web-app/utils/hooks/useCustomListApi';
import { format } from 'date-fns';
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { SubmitHandler, useForm } from 'react-hook-form';
const VendorPaymentModal = ({
  show,
  onHide,
  vendorDetail,
  refetchVendorPayoutDetail,
  vendorPayoutDetail,
  transactionDetail,
  refetchPaymentDetails
}: VendorPaymentModalType) => {
  const {
    register,
    handleSubmit,
    setError,
    reset,
    clearErrors,
    setValue,
    formState: { errors }
  } = useForm<IPaymentVendor>({
    defaultValues: {
      remarks: '',
      paymentAmount: '',
      paymentDate: '',
      paymentType: 'cheque',
      chequeNo: ''
    }
  });
  const resetFormState = () => {
    reset({
      remarks: '',
      paymentAmount: '',
      paymentDate: '',
      paymentType: 'cheque',
      chequeNo: ''
    });
    setPaymentMethod('cheque');
    clearErrors();
  };
  const adminUser = JSON.parse(localStorage.getItem('admin_user') as string);
  const [selectedBankOptions, setSelectedBankOptions] = useState({
    value: '',
    label: ''
  });
  const handleMakePayment: SubmitHandler<IPaymentVendor> = async data => {
    try {
      const today = new Date().toLocaleDateString().toString() as any;
      const createPaymentVendorBody: IcreatePaymentVendorBody = {
        amount: +data?.paymentAmount,
        transactionDate: format(new Date(data?.paymentDate || today), 'yyyy-MM-dd'),
        paymentType: 'cheque',
        paidFrom: adminUser?.employeeIdNo,
        vendor: vendorDetail?.id,
        chequeNo: data?.chequeNo,
        remarks: data?.remarks,
        bank: selectedBankOptions.value
      };

      await paymentServices.createPaymentVendor(createPaymentVendorBody);
      refetchVendorPayoutDetail({ id: vendorDetail?.id });
      resetFormState();
      onHide();
      Alert('success', `Rs ${+data?.paymentAmount} has been paid to ${vendorDetail?.vendorName} successfully.`);
      refetchPaymentDetails && refetchPaymentDetails({ vendorId: vendorDetail?.id });
    } catch (err: any) {
      const { message: errorMessage } = err?.response?.data ?? {};

      if (err.code === 'ERR_BAD_REQUEST') {
        errorMessage?.map?.((message: any) => {
          if (message.includes('chequeNo')) {
            setError('chequeNo', { message: message });
          }
          if (message.includes('paymentAmount')) {
            setError('paymentAmount', { message: message });
          }
          if (message.includes('paymentDate')) {
            setError('paymentDate', { message: message });
          }

          if (message.includes('paymentMethod')) {
            setError('paymentAmount', { message: message });
          }
          if (message.includes('remarks')) {
            setError('remarks', { message: message });
          }
        });
        if (typeof errorMessage === 'string') {
          Alert('error', errorMessage);
        }
      }
      if (err.message.includes('Request failed with status code 500')) {
        Alert('error', err.response.data.error);
      }
    }
  };

  const handleHideModal = () => {
    resetFormState();
    onHide();
  };

  const [paymentMethod, setPaymentMethod] = useState('cheque');

  const onChangeSelectHandler = (_: any = 'paymentDate', date: any) => {
    setValue('paymentDate', date);
  };

  const onChangeBankHandler = (options: any) => {
    setSelectedBankOptions(options);
  };

  const { data: bankListData } = useCustomListApi({
    queryKey: ['banksList'],
    getApiFn: getBanksList,
    enabled: true,
    params: {}
  }) as any;

  return (
    <CustomModal
      showModal={show}
      handleHideModal={handleHideModal}
      modalTitle="Make Payment"
      footerComponent={
        <>
          <button className="btn-tertiary pe-3" onClick={handleHideModal}>
            Cancel
          </button>
          <button
            className="btn"
            form="make-payment-to-vendor"
            // disabled={vendorPayoutDetail?.data?.remainingAmountToPay <= 0}
          >
            Submit
          </button>
        </>
      }
    >
      <div className="vendor-payment-detail">
        <div className="vendor-payment-detail-title body-regular mb-3">
          Vendor : <span className="vendor-payment-detail-value body-regular-semibold">{vendorDetail.vendorName}</span>
        </div>
        <div className="vendor-payment-detail-title body-regular mb-3">
          Total due amount :{' '}
          <span className="vendor-payment-detail-value body-regular-semibold">
            Rs. {formatAmountToStandardFormat(transactionDetail?.totalBalanceAmountTillNow ?? 0)}
          </span>
        </div>
        <div className="vendor-payment-detail-title body-regular">
          Total requested amount :{' '}
          <span className="vendor-payment-detail-value body-regular-semibold">
            Rs. {formatAmountToStandardFormat(vendorPayoutDetail?.data?.remainingAmountToPay)}
          </span>
        </div>
      </div>
      <div className="row">
        <form className="container mt-4" id="make-payment-to-vendor" onSubmit={handleSubmit(handleMakePayment)}>
          <Form.Group className="col-12 form-group-name mb-4">
            <Form.Control
              type="text"
              placeholder="Payment Amount"
              {...register('paymentAmount', {
                required: true,
                validate: value => parseInt(value) >= 10 || 'Amount should greater than Rs. 10',
                pattern: {
                  value: /^[1-9][0-9]*(\.[0-9]{1,2})?$/,
                  message: 'Amount: Invalid format. Only up to two decimal places are allowed.'
                }
              })}
              isInvalid={!!errors.paymentAmount}
              autoComplete="off"
            />
            <label
              htmlFor="floatingInputCustom"
              className={` customlabel-name   ${errors.paymentAmount ? 'text-danger' : ''}`}
            >
              Payment Amount<span className="asteriskVendor">*</span>
            </label>
            {errors.paymentAmount && <ErrorLabel message={errors.paymentAmount.message || 'Required'} />}
          </Form.Group>
          <Form.Group className="w-100 form-group-name mb-4">
            <CustomFormCalendar
              onSelectedDate={onChangeSelectHandler}
              showLabel={false}
              hasCalenderIcon={true}
              height={48}
            />
            <label
              htmlFor="floatingInputCustom"
              className={` customlabel-name   ${errors.paymentDate ? 'text-danger' : ''}`}
            >
              Payment Date<span className="asteriskVendor">*</span>
            </label>
            {errors.paymentDate && <ErrorLabel message={errors.paymentDate.message || 'Required'} />}
          </Form.Group>

          {paymentMethod.toLowerCase() === 'cheque' && (
            <>
              <CustomDropDownSelect
                title="Select Bank"
                options={returnDropdownOption(bankListData?.data?.data ?? [], { label: 'fullName', value: 'id' })}
                selectedOptions={selectedBankOptions}
                onChangeSelectHandler={onChangeBankHandler}
              />
              <Form.Group className="col-12 form-group-name my-4">
                <Form.Control
                  type="text"
                  placeholder="Cheque No."
                  {...register('chequeNo', { required: true })}
                  isInvalid={!!errors.chequeNo}
                  autoComplete="off"
                />
                <label
                  htmlFor="floatingInputCustom"
                  className={`customlabel-name ${errors.chequeNo ? 'text-danger' : ''}`}
                >
                  Cheque No.<span className="asteriskVendor">*</span>
                </label>
                {errors.chequeNo && <ErrorLabel message={errors.chequeNo.message || 'Required'} />}
              </Form.Group>
            </>
          )}
          <Form.Group className="col-12 form-group-id mb-4">
            <Form.Control
              type="text"
              placeholder="Remarks"
              {...register('remarks', { required: true })}
              isInvalid={!!errors.remarks}
              autoComplete="off"
              className="form-control form-input-small"
            />
            <label htmlFor="floatingInputCustom" className={`customlabel-id ${errors.remarks ? 'text-danger' : ''}`}>
              Remarks<span className="asteriskVendor">*</span>
            </label>
            {errors.remarks && <ErrorLabel message={errors.remarks.message || 'Required'} />}
          </Form.Group>
        </form>
      </div>
    </CustomModal>
  );
};

export default VendorPaymentModal;
